import React from 'react';
import { CustomButtonProps } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CustomButton: React.FC<CustomButtonProps> = ({ title, description, image, href }) => {
  return (
    <a href={`${href}`} className="custom-button">
      <p className="custom-button-header">{title}</p>
      <FontAwesomeIcon className="custom-button-image" icon={image} />
      <p className="custom-button-description">{description}</p>
    </a>
  );
};
