import React from 'react';
import { Button, Col, Modal, Spinner } from 'react-bootstrap';
import { NewsProps } from './types';
import { deleteNews } from '@src/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomTag } from '../tags';

export const NewsComponent: React.FC<NewsProps> = ({ news, setNews, isAdmin }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [newsToDlete, setNewsToDelete] = React.useState<number | null>(null);

  const confirmDeleteNews = async () => {
    if (newsToDlete === null) return;

    setLoading(true);
    try {
      const response = await deleteNews(newsToDlete);
      if (response?.status === 200) {
        setNews(news.filter((news) => news.news_id !== newsToDlete));
        setNewsToDelete(null);
      }
    } catch (error) {
      console.error('Failed to delete news:', error);
    } finally {
      setShowModal(false);
      setLoading(false);
    }
  };

  const handleDeleteNews = (newsId: number) => {
    setNewsToDelete(newsId);
    setShowModal(true);
  };

  return (
    <>
      <Col className="news-card mb-3">
        <div className="news-header">
          <h1 className="admin-header news-header">News and improvements</h1>
          <hr className="news-divider" />
        </div>
        <Col className="news-content mb-3">
          {news.map((newsItem) => (
            <Col key={newsItem.news_id} className="news-item mb-3">
              {/* News Image */}
              <img className="news-image" src={newsItem.image_url} alt={newsItem.news_title} />
              <div className="news-card-content">
                {/* Tags Section */}
                <div className="changelog-div news-tags mb-2">
                  {newsItem.news_tags.map((tag, index) => (
                    <CustomTag key={index} text={tag.text} color={tag.color} />
                  ))}
                </div>

                {/* Title and Author */}
                <h2 className="news-title">{newsItem.news_title}</h2>
                <div className="news-info">
                  <span>Shared by {newsItem.news_author}</span>
                  <span>-</span>
                  <span>{newsItem.news_created ? new Date(newsItem.news_created).toLocaleDateString('en-GB') : 'Invalid Date'}</span>
                </div>

                {/* News Content */}
                <p className="news-description" dangerouslySetInnerHTML={{ __html: newsItem.news_content }} />

                {/* Admin Delete Button */}
                {isAdmin && (
                  <Button className="news-delete-btn" onClick={() => handleDeleteNews(newsItem.news_id)}>
                    <FontAwesomeIcon icon="trash-can" />
                  </Button>
                )}
              </div>
            </Col>
          ))}
        </Col>
      </Col>

      <Modal className="modal-placement" show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this news entry?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          {loading ? <Spinner className="custom-spinner-small-modal"></Spinner> : <Button onClick={confirmDeleteNews}>Yes, Delete</Button>}
        </Modal.Footer>
      </Modal>
    </>
  );
};
