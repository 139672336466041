import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import type { RentmanSettingModuleProps } from '@src/pages/settings/types';

export const RentmanMatchPDFInvoicePrice: React.FC<RentmanSettingModuleProps> = ({ rentmanUserDefaultData, setRentmanUserDefaultData }) => {
  const handleFormCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRentmanUserDefaultData({
      ...rentmanUserDefaultData,
      match_pdf_invoice_price: event.target.checked,
    });
  };

  const uploadPdfType = rentmanUserDefaultData.upload_pdf_type;

  return (
    <>
      {rentmanUserDefaultData.upload_pdf && rentmanUserDefaultData.upload_pdf === true && uploadPdfType !== 'invoices' && (
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSettings">
          <Form.Label column sm={6}>
            Enable matching contract or quotation PDF by invoice price (Disabled chooses latest)
          </Form.Label>
          <Col sm={6}>
            <Form.Check
              type="switch"
              id="pdf-upload-switch"
              label={rentmanUserDefaultData.match_pdf_invoice_price ? 'Enabled' : 'Disabled'}
              checked={rentmanUserDefaultData.match_pdf_invoice_price ? rentmanUserDefaultData.upload_pdf : false}
              onChange={handleFormCheckChange}
            />
          </Col>
        </Form.Group>
      )}
    </>
  );
};
