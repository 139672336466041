import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { ReferenceOptions } from '../types';

export const EconomicReferenceTitle: React.FC<ReferenceOptions> = ({ economicUserDefaultData, setEconomicUserDefaultData, referenceOptions }) => {
  const handleReferenceTitleChange = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const option = event.target.options[event.target.selectedIndex];

    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        reference_title: option.value,
      });
    }
  };

  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSettings">
      <Form.Label column sm={6}>
        Select value for title (Overskrift)
      </Form.Label>
      <Col sm={6}>
        <FloatingLabel controlId="floatingSelectReferenceTitleValue" label="Select reference title">
          <Form.Select aria-label="Floating label select" value={economicUserDefaultData.reference_title} onChange={handleReferenceTitleChange}>
            {referenceOptions('title')}
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Form.Group>
  );
};
