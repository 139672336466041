import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { DashboardPage } from '@src/pages/dashboard';
import { UserConfigPage } from '@src/pages/connection';
import { useUsersContext } from '@src/contexts/users';
import { LoginPage } from '@src/pages/login';
import { AccountPage } from '@src/pages/account';
import { SettingsPage } from '../settings';
import { SyncOverviewPage } from '../sync-overview';
import { LogPage } from '../log';
import { Col, Container, Row, Nav, Offcanvas, NavDropdown, Navbar, Dropdown } from 'react-bootstrap';

import { UsersActions } from '@src/contexts/users/types';

import companyLogo from '@public/img/logo_green.png';
import { CreateAccountPage } from '../admin/createAccount';
import { SetupPage } from '../setup';
import { UsersPage } from '../admin/allUsers';
import { ResetPassword } from '../login/resetPassword';

import logOut from '../../../public/img/logout-arrow.svg';
import { TermsAndConditionsPage } from '../terms/terms-and-conditions';
import { DataProcessingAgreementPage } from '../terms/data-processing-agreement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangelogAdminPage } from '../admin/adminChangelog';
import { ChangelogPage } from '../changelog';
import { NewsAdminPage } from '../admin/adminNews';

export const MainPage: React.FC = () => {
  const { usersState, usersDispatch } = useUsersContext();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoggedInAsUser, setIsLoggedInAsUser] = React.useState(false);

  const [show, setShow] = React.useState(false);

  const toggleOffCanvas = (): void => {
    setShow((show) => !show);
  };

  const SignOut = (): void => {
    usersDispatch({ type: UsersActions.LOGOUT, payload: '' });
  };

  const adminSignOut = (): void => {
    const adminJwt = localStorage.getItem('adminJwt');
    if (adminJwt !== null) {
      localStorage.setItem('jwt', adminJwt);
      localStorage.removeItem('adminJwt');
      location.href = '/all-users';
    }
  };

  const isAdminUser = (): void => {
    const regularJwt = localStorage.getItem('jwt');
    const adminJwt = localStorage.getItem('adminJwt');

    if (regularJwt && adminJwt) {
      if (regularJwt !== adminJwt) {
        setIsLoggedInAsUser(true);
      }
    } else if (adminJwt) {
      setIsLoggedInAsUser(true);
    }
  };

  useEffect(() => {
    isAdminUser();
    isApiConnected();
  }, []);

  const isApiConnected = (): boolean => {
    if (usersState.user.economicConnected) {
      console.log('isApiConnected: ' + usersState.user.economicConnected);
      return usersState.user.economicConnected;
    }
    console.log('isNotApiConnected: ' + usersState.user.economicConnected);
    return false;
  };

  //Checks if url is in searchbar
  const queryString = window.location.search;
  console.log(queryString);

  const urlParams = new URLSearchParams(queryString);

  const resetToken = urlParams.get('token');

  if (resetToken) {
    return <ResetPassword />;
  } else if (usersState.token.length === 0) {
    return <LoginPage />;
  }

  return (
    <BrowserRouter>
      <Container fluid className="main">
        <Row className="main-header">
          <Col className="main-logo" xs={4}>
            <a href="/">
              <img src={companyLogo} className="" alt="IT Stack logo med animation" width="120" loading="lazy" />
            </a>
          </Col>
          <Col xs={8} className="d-flex justify-content-end sign-out">
            {isLoggedInAsUser ? (
              <Row className="sign-out-item no-gutters">
                <Col className="d-flex-fit-content align-items-center ">
                  <p className="sign-out-item-text">
                    {usersState.user.company} ({usersState.user.id})
                  </p>
                </Col>
                <Col className="sign-out-item d-flex align-items-center col-1">
                  <img width={15} src={logOut}></img>
                  <b className="sign-out-item-link" onClick={adminSignOut}>
                    Sign out
                  </b>
                </Col>
              </Row>
            ) : (
              <Row className="sign-out-item-no-bg">
                <Col>
                  <p className="m-0">
                    <b>{usersState.user.company}</b>
                  </p>
                  <p className="m-0">ID: {usersState.user.id}</p>
                </Col>
                <Col>
                  <a href="/#" onClick={SignOut} className="sign-out-item-link">
                    <b>Sign out</b>
                  </a>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Row className="main-content">
          <Col sm={12} lg={2} className="p-2">
            <Row className="d-none d-lg-inline">
              <Col className="menu-wrapper">
                <Navbar>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto flex-column">
                      <LinkContainer to="/">
                        <Nav.Link className="custom-nav-link">
                          <FontAwesomeIcon icon="house" className="me-2" />
                          Dashboard
                        </Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/sync-overview">
                        <Nav.Link className="custom-nav-link">
                          <FontAwesomeIcon icon="list" className="me-2" />
                          Sync overview
                        </Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/setup-guide">
                        <Nav.Link className="custom-nav-link">
                          <FontAwesomeIcon icon="book-open" className="me-2" />
                          Setup guide
                        </Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/changelog">
                        <Nav.Link className="custom-nav-link">
                          <FontAwesomeIcon icon="book" className="me-2" />
                          Changelog
                        </Nav.Link>
                      </LinkContainer>
                      <div
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                        className={isOpen ? 'dropdown-menu-open custom-nav-link' : 'custom-nav-link'}
                      >
                        <Dropdown className="navbar-dropdown" autoClose={false} navbar={true}>
                          <Dropdown.Toggle id="dropdown-autoclose-false" className="custom-nav-link">
                            <FontAwesomeIcon icon="wrench" className="me-2" />
                            Integration
                          </Dropdown.Toggle>

                          <Dropdown.Menu onClick={(e) => e.stopPropagation()}>
                            <LinkContainer to="/account">
                              <Dropdown.Item className="custom-nav-link">Account</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/connection-config">
                              <Dropdown.Item className="custom-nav-link">Connections</Dropdown.Item>
                            </LinkContainer>
                            {isApiConnected() === true && (
                              <LinkContainer to="/settings">
                                <Dropdown.Item className="custom-nav-link">Settings</Dropdown.Item>
                              </LinkContainer>
                            )}
                            <LinkContainer to="/log">
                              <Dropdown.Item className="custom-nav-link">Log</Dropdown.Item>
                            </LinkContainer>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      {usersState.user.role[0] === 'admin' || isLoggedInAsUser ? (
                        <Dropdown className="navbar-dropdown" autoClose={false} navbar={true}>
                          <Dropdown.Toggle id="dropdown-autoclose-false" className="custom-nav-link">
                            <FontAwesomeIcon icon="lock" className="me-2" />
                            Admin
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <LinkContainer to="/all-users">
                              <Dropdown.Item className="custom-nav-link">Users</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/create-account">
                              <Dropdown.Item className="custom-nav-link">Create Account</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/changelog-admin">
                              <Dropdown.Item className="custom-nav-link">Changelog settings</Dropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/news-admin">
                              <Dropdown.Item className="custom-nav-link">News settings</Dropdown.Item>
                            </LinkContainer>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        ''
                      )}
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Col>
            </Row>

            <Row className="d-block d-sm-block d-md-block d-lg-none">
              <Navbar collapseOnSelect expand={false}>
                <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={toggleOffCanvas} />
                <Navbar.Offcanvas id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" placement="start" show={show} onHide={toggleOffCanvas}>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Col className="user-wrapper">
                      <p>
                        <b>{usersState.user.company}</b>
                      </p>
                      <p>ID: {usersState.user.id}</p>
                    </Col>

                    <hr />

                    <Nav className="justify-content-end flex-grow-1 pe-3 offcanvas--menu mobile-menu">
                      <LinkContainer to="/" onClick={toggleOffCanvas}>
                        <Nav.Link className="custom-nav-link">Dashboard</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/sync-overview" onClick={toggleOffCanvas}>
                        <Nav.Link className="custom-nav-link">Sync overview</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/setup-guide" onClick={toggleOffCanvas}>
                        <Nav.Link className="custom-nav-link">Setup guide</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/changelog" onClick={toggleOffCanvas}>
                        <Nav.Link className="custom-nav-link">Changelog</Nav.Link>
                      </LinkContainer>
                      <NavDropdown title="Integration" id="basic-nav-dropdown">
                        <LinkContainer to="/account" onClick={toggleOffCanvas}>
                          <Nav.Link className="custom-nav-link">Account</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/connection-config" onClick={toggleOffCanvas}>
                          <Nav.Link className="custom-nav-link">Connections</Nav.Link>
                        </LinkContainer>
                        {isApiConnected() === true && (
                          <LinkContainer to="/settings" onClick={toggleOffCanvas}>
                            <Nav.Link className="custom-nav-link">Settings</Nav.Link>
                          </LinkContainer>
                        )}
                        <LinkContainer to="/log" onClick={toggleOffCanvas}>
                          <Nav.Link className="custom-nav-link">Log</Nav.Link>
                        </LinkContainer>
                      </NavDropdown>
                      <NavDropdown title="Admin" id="basic-nav-dropdown-2">
                        <LinkContainer to="/all-users" onClick={toggleOffCanvas}>
                          <Nav.Link className="custom-nav-link">Users</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/create-account" onClick={toggleOffCanvas}>
                          <Nav.Link className="custom-nav-link">Create Account</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/changelog-admin" onClick={toggleOffCanvas}>
                          <Nav.Link className="custom-nav-link">Changelog settings</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/news-admin" onClick={toggleOffCanvas}>
                          <Nav.Link className="custom-nav-link">News settings</Nav.Link>
                        </LinkContainer>
                      </NavDropdown>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Navbar>
            </Row>
          </Col>

          <Col sm={12} lg={10} className="content-wrapper">
            <Routes>
              <Route path="/" element={<DashboardPage />} />
              <Route path="/sync-overview" element={<SyncOverviewPage />} />
              <Route path="/connection-config" element={<UserConfigPage />} />
              <Route path="/create-account" element={<CreateAccountPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/log" element={<LogPage />} />
              <Route path="/setup-guide" element={<SetupPage />} />
              <Route path="/all-users" element={<UsersPage />} />
              <Route path="terms-and-conditions" element={<TermsAndConditionsPage />} />
              <Route path="data-processing-agreement" element={<DataProcessingAgreementPage />} />
              <Route path="/changelog-admin" element={<ChangelogAdminPage />} />
              <Route path="/changelog" element={<ChangelogPage />} />
              <Route path="/news-admin" element={<NewsAdminPage />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </BrowserRouter>
  );
};
