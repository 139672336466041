import React from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';

import type { ReferenceOptions } from '../types';

export const EconomicReferenceText1: React.FC<ReferenceOptions> = ({ economicUserDefaultData, setEconomicUserDefaultData, referenceOptions }) => {
  const handleReferenceText1Change = (event: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const option = event.target.options[event.target.selectedIndex];

    if (setEconomicUserDefaultData) {
      setEconomicUserDefaultData({
        ...economicUserDefaultData,
        reference_text_1: option.value,
      });
    }
  };

  return (
    <Form.Group as={Row} className="mb-3" controlId="formHorizontalRentmanSettings">
      <Form.Label column sm={6}>
        Select value for text 1 (Tekst 1)
      </Form.Label>
      <Col sm={6}>
        <FloatingLabel controlId="floatingSelectReferenceText1Value" label="Select reference text 1">
          <Form.Select aria-label="Floating label select" value={economicUserDefaultData.reference_text_1} onChange={handleReferenceText1Change}>
            {referenceOptions('text-2')}
          </Form.Select>
        </FloatingLabel>
      </Col>
    </Form.Group>
  );
};
