import { createNews, getAllNews } from '@src/Api';
import { NewsComponent } from '@src/components/news';
import { News } from '@src/components/news/types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  ContentEditableEvent,
  Editor,
  EditorProvider,
  Separator,
  Toolbar,
} from 'react-simple-wysiwyg';
import { ChangelogTags, CreateNews } from './types';
import { CustomTag } from '@src/components/tags';
import { CustomToast } from '@src/components/toast';

export const NewsAdminPage: React.FC = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastError, setToastError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [news, setNews] = useState<News[]>([]);
  const [html, setHtml] = useState<string>('');
  const [tags, setTags] = useState<ChangelogTags[]>([]);
  const [tagText, setTagText] = useState('');
  const [tagColor, setTagColor] = useState('#6c757d');
  const [title, setTitle] = useState<string>('');
  const [author, setAuthor] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [errors, setErrors] = useState<{ date: boolean; title: boolean }>({ date: false, title: false });

  const handleChange = (event: ContentEditableEvent) => {
    setHtml(event.target.value);
  };

  const handleAddTag = () => {
    if (tagText.trim()) {
      setTags([...tags, { text: tagText, color: tagColor }]);
      setTagText('');
      setTagColor('#6c757d');
    }
  };

  const handleRemoveTag = (index: number) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const fetchNews = async () => {
    const response = await getAllNews();

    if (response) {
      setNews(response.data);
    }
  };

  const handleChangelogCreation = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    const hasErrors = {
      date: !date,
      title: !title,
    };

    setErrors(hasErrors);

    if (hasErrors.date || hasErrors.title) {
      return;
    }

    setLoading(true);

    const newsToCreate: CreateNews = {
      news_title: title,
      news_content: html,
      news_created: new Date(date).toISOString().slice(0, 19).replace('T', ' '),
      news_author: author,
      image_url: imageUrl,
      news_tags: tags,
    };

    const response = await createNews(newsToCreate);

    if (response?.status !== 201 && response?.statusText) {
      const errorMessage = response?.statusText || 'An unknown error occurred.';
      setToastError(errorMessage);
      setShowToast(true);
    } else {
      fetchNews();
      setToastError(null);
      setShowToast(true);
      setTags([]);
      setHtml('');
      setTitle('');
      setDate('');
      setAuthor('');
      setImageUrl('');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <>
      <Row className="admin">
        <Col className="p-0">
          <Row className="admin-header-row">
            <Col>
              <h1 className="admin-header">Add new changelog entry</h1>
              <hr className="mt-2" />
            </Col>
          </Row>

          {/* Changelog Form */}
          <Row className="admin-form-row">
            <Col md={12}>
              <Form>
                <Form.Group className="admin-form-input">
                  <Form.Label>Title</Form.Label>
                  <Form.Label className="required-star">*</Form.Label>
                  <Form.Control placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} isInvalid={errors.title} />
                  <Form.Control.Feedback type="invalid">Title is required</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-3 admin-form-input">
                  <Form.Label>Author</Form.Label>
                  <Form.Control placeholder="Enter author" value={author} onChange={(e) => setAuthor(e.target.value)} />
                </Form.Group>

                <Form.Group className="mt-3 admin-form-input">
                  <Form.Label>Image Url</Form.Label>
                  <Form.Control placeholder="Enter image url" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
                </Form.Group>

                <Form.Group className="mt-3 admin-form-input">
                  <Form.Label>Date</Form.Label>
                  <Form.Label className="required-star">*</Form.Label>
                  <Form.Control type="date" value={date} onChange={(e) => setDate(e.target.value)} isInvalid={errors.date} />
                  <Form.Control.Feedback type="invalid">Date is required</Form.Control.Feedback>
                </Form.Group>

                {/* Tags Section */}
                <Form.Group className="mt-3 admin-form-input">
                  <Form.Label>Add Tags</Form.Label>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <Form.Control type="text" placeholder="Tag text" value={tagText} onChange={(e) => setTagText(e.target.value)} />
                    <Form.Control type="color" value={tagColor} onChange={(e) => setTagColor(e.target.value)} />
                    <Form.Control type="text" placeholder="#Hexcode" value={tagColor} onChange={(e) => setTagColor(e.target.value)} />
                    <Button className="mt-0 admin-form-btn" onClick={handleAddTag}>
                      +
                    </Button>
                  </div>

                  {/* Render Tags */}
                  <div>
                    {tags.map((tag, index) => (
                      <div key={index} className="tag-container">
                        <CustomTag text={tag.text} color={tag.color} />
                        <span className="tag-remove" onClick={() => handleRemoveTag(index)}>
                          &times;
                        </span>
                      </div>
                    ))}
                  </div>
                </Form.Group>

                {/* WYSIWYG Editor */}
                <Form.Group className="mt-3">
                  <EditorProvider>
                    <Toolbar>
                      <BtnUndo />
                      <BtnRedo />
                      <Separator />
                      <BtnBold />
                      <BtnItalic />
                      <BtnUnderline />
                      <BtnNumberedList />
                      <BtnBulletList />
                      <BtnStrikeThrough />
                      <Separator />
                      <BtnLink />
                      <BtnStyles />
                    </Toolbar>
                    <Editor value={html} onChange={handleChange} />
                  </EditorProvider>
                </Form.Group>

                {/* Submit Button */}
                <Form.Group className="mt-3 mb-5">
                  {!loading ? (
                    <Button className="admin-form-btn" type="submit" onClick={(e) => handleChangelogCreation(e)}>
                      Create
                    </Button>
                  ) : (
                    <Spinner animation="border" />
                  )}
                </Form.Group>
              </Form>
            </Col>
          </Row>

          <Container className="toast-b-right">
            {/* Toast Notification */}
            {showToast && (
              <CustomToast
                duration={10000}
                show={showToast}
                title={toastError ? 'Error' : 'news entry created'}
                message={toastError || 'New news entry was successfully created.'}
                onClose={() => setShowToast(false)}
                type={toastError ? 'error' : 'success'}
              />
            )}
          </Container>

          <hr />

          {/* Display Changelog Entries */}
          <NewsComponent news={news} setNews={setNews} isAdmin={true} />
        </Col>
      </Row>
    </>
  );
};
