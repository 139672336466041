import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useUsersContext } from '@src/contexts/users';
import { CustomButton } from '@src/components/custom-button';

//Images
import rentmanEconomic from '@public/img/rentman-economic-combo.svg';
import { NewsComponent } from '@src/components/news';
import { News } from '@src/components/news/types';
import { getAllNews, getAmountOfInvoices } from '@src/Api';
import { StatsButton } from '@src/components/stats-button';

export const DashboardPage: React.FC = () => {
  const { usersState } = useUsersContext();

  const [news, setNews] = useState<News[]>([]);
  const [totalInvoices, setTotalInvoices] = useState<number>(0);
  const [monthlyInvoices, setMonthlyInvoices] = useState<number>(0);
  const [failedInvoices, setFailedInvoices] = useState<number>(0);
  const [completedInvoices, setCompletedInvoices] = useState<number>(0);
  const [skippedInvoices, setSkippedInvoices] = useState<number>(0);

  useEffect(() => {
    const fetchNews = async () => {
      const response = await getAllNews();
      if (response) {
        setNews(response.data);
      }
    };

    const fetchAmountOfInvoices = async () => {
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();
      const response = await getAmountOfInvoices(currentMonth, currentYear);
      if (response) {
        setTotalInvoices(response.data.amount);
        setMonthlyInvoices(response.data.monthlyAmount);
        setFailedInvoices(response.data.failedAmount);
        setCompletedInvoices(response.data.completedAmount);
        setSkippedInvoices(response.data.skippedAmount);
      }
    };

    if (isApiConnected() === true) {
      fetchAmountOfInvoices();
    }

    fetchNews();
  }, []);

  const isApiConnected = (): boolean => {
    if (usersState.user.rentmanConnected) {
      return usersState.user.rentmanConnected;
    }

    return false;
  };

  return (
    <>
      <Row className="dashboard">
        <Col lg={8} className="p-0 dashboard-container">
          {isApiConnected() === false && (
            <>
              <Row>
                <div>
                  <h1 className="sync-overview-header">Rentman E-conomic Integration</h1>
                  <hr className="mt-2" />
                </div>
                <Col lg={8}>
                  <Row className="dashboard-welcome">
                    <h1 className="dashboard-main-title"> Hi {usersState.user.company}!</h1>
                    <Row>
                      <span>Welcome to the integration for Rentman and E-conomic</span>
                    </Row>
                    <Row>
                      <span>On the dashboard you can navigate the integration. When the integration is set up, you will be able to see statistics</span>
                    </Row>
                  </Row>
                </Col>
                <Col className="dashboard-main-image d-flex">
                  <img src={`${rentmanEconomic}`} alt="RentmanEconomic"></img>
                </Col>
              </Row>
            </>
          )}
          {isApiConnected() === true && (
            <>
              <div>
                <h1 className="sync-overview-header">Statistics</h1>
                <hr className="mt-2" />
              </div>
              <div className="dashboard-stats-row">
                <StatsButton title={'All-Time Invoices'} amount={totalInvoices} />
                <StatsButton title={'Invoices This Month'} amount={monthlyInvoices} />
              </div>
              <div className="dashboard-buttons-row">
                <StatsButton title={'Completed Invoices'} amount={completedInvoices} />
                <StatsButton title={'Failed Invoices'} amount={failedInvoices} />
                <StatsButton title={'Skipped Invoices'} amount={skippedInvoices} />
              </div>
            </>
          )}
          <div>
            <h1 className="sync-overview-header">Navigation</h1>
            <hr className="mt-2" />
          </div>

          <div className="dashboard-buttons-row">
            <CustomButton description={'Use the setup guide to get started'} title={'Setup Guide'} image={'book-open'} href="/setup-guide" />
            <CustomButton description={'Adjust Rentman and economic connections'} title={'Connections'} image={'wifi'} href="/connection-config" />
            <CustomButton description={'Keep up to date with updates and bug fixes'} title={'Changelog'} image={'book'} href="/changelog" />

            {isApiConnected() === true && (
              <>
                <CustomButton description={'Adjust your settings for the integration'} title={'Settings'} image={'gear'} href="/settings" />
                <CustomButton description={'See an overview of invoices'} title={'Invoices'} image={'file'} href="/sync-overview" />
                <CustomButton description={'See a log of all events for the integration'} title={'Invoice Logs'} image={'list'} href="/log" />
              </>
            )}
          </div>
        </Col>
        <Col>
          <NewsComponent news={news} setNews={setNews} isAdmin={false} />
        </Col>
      </Row>
      <Row></Row>
    </>
  );
};
