import React from 'react';
import { Col } from 'react-bootstrap';
import { StatsButtonProps } from './types';

export const StatsButton: React.FC<StatsButtonProps> = ({ title, amount }) => {
  return (
    <Col className="custom-button stats-button">
      <div className="custom-button-content">
        <p className="custom-button-header">{title}</p>
        <p className="custom-button-number">{amount}</p>
      </div>
    </Col>
  );
};
